import React from "react";
import Chart_Container from "./Chart_Container";
const Shree_Jackpot = (props) => {
  return (
   
      <Chart_Container {...props}  responsive_Class={"col-xxl-6 col-xl-6  col-md-6 "}/>

  );
};

export default Shree_Jackpot;
